import React from "react"
import { Link } from "gatsby"

import styled, { ThemeProvider } from "styled-components"
import HomePage from "src/components/home-page/home-page"
import {
  Banner,
  BannerContentFull,
  BannerContentGoogle,
  BannerText,
} from "src/components/Banner"
import { PageTitle } from "src/components/Typography"
import SEO from "src/components/seo"
import "src/components/common/custom-bluma.scss"

import { GoogleHeader } from "src/components/Header"

import Container from "src/components/Container"
import Button from "src/components/Button"

import {
  NodeIcon,
  ReactIcon,
  SkillList,
  WorldBackground,
} from "../components/Banner"
import { darkTheme } from "../utils/themes"
import { LightFooter } from "src/components/Footer"

import AccurKardia from "src/images/company/AccurKardia.svg"
import Clarusrd from "src/images/company/clarusrd.svg"
import MCompany from "src/images/company/M.svg"
import MYOB from "src/images/company/MYOB.svg"

const CompanyBlock = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  img {
    margin: 2rem 0;
  }
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
`

const HireDeveloperPage = ({ location }) => (
  <ThemeProvider theme={darkTheme}>
    <div>
      <GoogleHeader />
      <Banner>
        <WorldBackground />
        <Container>
          <SEO
            title="Archimydes | Pay for Working Code, Not Hours Worked"
            description="Archimydes is a community of developers, architects, and analysts that can augment your core development team. We leverage our proprietary methodology to deliver quality code fast."
            location={location}
          />
          <BannerContentGoogle style={{ flexDirection: "column" }}>
            <BannerContentFull>
              <PageTitle>
                Pay for <em>Working Code</em>, Not Hours Worked
              </PageTitle>
              <BannerText>
                Stop trying to hire more developers, and start receiving great
                code directly with Archimydes. You focus on the success criteria
                and we deliver in hours or days, not weeks or months.
              </BannerText>
              <SkillList>
                <p>We support</p>
                <ReactIcon></ReactIcon>
                <NodeIcon></NodeIcon>
              </SkillList>
              <Link to="/signup">
                <Button>Get Started</Button>
              </Link>
            </BannerContentFull>
            <div>
              <br />
              <br />
              <div
                className="has-text-centered is-size-5"
                style={{ opacity: 0.5 }}
              >
                Our clients
              </div>
              <br />
              <br />
              <CompanyBlock>
                <img src={AccurKardia} alt="Logo" />
                <img src={Clarusrd} alt="Logo" />
                <img src={MCompany} alt="Logo" />
                <img src={MYOB} alt="Logo" />
              </CompanyBlock>
            </div>
          </BannerContentGoogle>
        </Container>
      </Banner>
      <div className="home-page-content">
        <HomePage />
        <LightFooter />
      </div>
    </div>
  </ThemeProvider>
)

export default HireDeveloperPage
